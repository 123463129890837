import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const SchedulingPage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <link rel="canonical" href="https://meridianendoindy.com/patient-information/scheduling/"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Scheduling endodontics pc, Indianapolis IN</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Scheduling endodontics pc, Indianapolis IN"/>
        <meta name="description" content="Appointment Scheduling for Indianapolis IN Endodontist Dr. Broady. 317-846-4980"/>
        <meta name="og:description" content="Appointment Scheduling for Indianapolis IN Endodontist Dr. Broady. 317-846-4980"/>
        <meta property="og:url" content="https://meridianendoindy.com/patient-information/scheduling/"/>
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="https://meridianendoindy.com/patient-information/scheduling/" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Scheduling endodontics pc, Indianapolis IN"/>
        <meta name="twitter:description" content="Appointment Scheduling for Indianapolis IN Endodontist Dr. Broady. 317-846-4980"/>
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="scheduling-hero-section-bg hero-bg"
        fluid={hero_background}
        alt="hero background image"
      >
        <div id="scheduling-hero-section">
          <Container>
            <div className="col-lg-6 left-content pb-5">
              <h1 className="primary-text hero-heading">
                Scheduling
              </h1>
              <p className="hero-text">
                Meridian Endodontics is open Monday through Friday from 8:00 AM until 5:00 PM. We will schedule your appointment as promptly as possible.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-6">
              <Img fluid={hero_img} className="hero-img" alt="lady wearing yellow shoulder top"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg"></section>
      <section id="scheduling-section-1">
        <Container>
          <div className="col-lg-12">
            <p>
              Meridian Endodontics tries to accommodate all patients with an open schedule. Our doctors are available Monday through Friday from 8:00 AM until 5:00 PM. Appointments fill fast, so schedule it as soon as possible. If this is an emergency, contact our office promptly, and we'll do our best to see you the same day.
            </p>
            <p>
              While we work to minimize patients' wait time for an appointment, as we know they're experiencing pain and discomfort, situations can prolong surgeries and treatments, extending the wait. Please understand that emergencies can cause delays for some patients, and we appreciate your patience during these busy times.
            </p>
            <strong><p>To schedule your initial consultation or for questions about treatment, call us at <a href="tel:3178464980">317-846-4980.</a></p></strong>
          </div>
          <div className="col-lg-6">
            <div className="container-box">
              <h5 className="primary-text">Information</h5>
              <div className="row">
                <div className="col-lg-3 mt-2">
                  <div className="address-container">
                    <p>Address: </p>
                  </div>
                  <p>Phone: </p>
                  <p>Fax: </p>
                  <p>Email: </p>
                </div>
                <div className="col-lg-9 mt-2">
                  <div className="address-container">
                    <p>9002 North Meridian St, Suite 201 Indianapolis, IN 46260</p>
                  </div>
                  <p>317-846-4980</p>
                  <p>317-846-4982</p>
                  <p>office@meridianendoindy.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="container-box p-b-6">
              <h5 className="primary-text">Office Hours</h5>
              <div className="row">
                <div className="col-lg-4 mt-2">
                  <p>Monday:</p>
                  <p>Tuesday:</p>
                  <p>Wednesday:</p>
                  <p>Thursday:</p>
                  <p>Friday:</p>
                </div>
                <div className="col-lg-8 mt-2">
                  <p>8:00 AM - 5:00 PM</p>
                  <p>8:00 AM - 5:00 PM</p>
                  <p>8:00 AM - 5:00 PM</p>
                  <p>8:00 AM - 5:00 PM</p>
                  <p>8:00 AM - 5:00 PM</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section id="scheduling-section-2">
        <ContactForm/>
      </section>
    </Layout>
  )
}

export default SchedulingPage

export const query = graphql`
  query SchedulingPageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "scheduling-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 459, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
